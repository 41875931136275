@font-face {
	font-family: 'xivicons';
	src: url('./FFXIVAppIcons.eot');
	src: url('./FFXIVAppIcons.eot?#iefix') format('embedded-opentype'),
	url('./FFXIVAppIcons.woff') format('woff'), url('./FFXIVAppIcons.ttf') format('truetype'),
	url('./FFXIVAppIcons.svg#FFXIVAppIcons') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FFXIV';
	src: url('FFXIV_Lodestone_SSF.ttf') format('truetype'),
	url('FFXIV_Lodestone_SSF.woff') format('woff');
	unicode-range: U+E020-E0DB;
}