.xiv-app_address:before{content:'\e010';}
.xiv-app_friend:before{content:'\e020';}
.xiv-app_fc:before{content:'\e021';}
.xiv-app_ls:before{content:'\e022';}
.xiv-app_favorite:before{content:'\e030';}
.xiv-app_favorite_list:before{content:'\e031';}
.xiv-app_group:before{content:'\e050';}
.xiv-app_group_add:before{content:'\e052';}
.xiv-app_talk:before{content:'\e060';}
.xiv-app_character:before{content:'\e070';}
.xiv-app_character_add:before{content:'\e072';}
.xiv-app_character_info:before{content:'\e073';}
.xiv-app_mobile:before{content:'\e074';}
.xiv-CrossWorld:before{content:'\e075';}
.xiv-FFXIVMeteo:before{content:'\e076';}
.xiv-ViewMove:before{content:'\e077';}
.xiv-app_world_home:before{content:'\e078';}
.xiv-app_world_wanderer:before{content:'\e079';}
.xiv-app_block:before{content:'\e080';}
.xiv-app_block_list:before{content:'\e081';}
.xiv-app_black:before{content:'\e090';}
.xiv-app_black_list:before{content:'\e091';}
.xiv-NaviLeft:before{content:'\e100';}
.xiv-NaviRight:before{content:'\e101';}
.xiv-NaviUp:before{content:'\e102';}
.xiv-NaviBottom:before{content:'\e103';}
.xiv-NaviArrowLeft:before{content:'\e110';}
.xiv-NaviArrowRight:before{content:'\e111';}
.xiv-app_action_paging_prev:before{content:'\e112';}
.xiv-app_action_paging_next:before{content:'\e113';}
.xiv-NavigationClose:before{content:'\e120';}
.xiv-Add:before{content:'\e121';}
.xiv-app_action_block_open:before{content:'\e122';}
.xiv-app_action_block_close:before{content:'\e123';}
.xiv-app_status_online:before{content:'\e150';}
.xiv-app_status_contents:before{content:'\e151';}
.xiv-app_status_loading:before{content:'\e152';}
.xiv-app_online_unknown:before{content:'\e161';}
.xiv-NaviMenu:before{content:'\e200';}
.xiv-app_navi_drawer:before{content:'\e201';}
.xiv-app_navi_paste:before{content:'\e202';}
.xiv-app_navi_menu_dot:before{content:'\e204';}
.xiv-NaviSearch:before{content:'\e210';}
.xiv-NaviInputClear:before{content:'\e211';}
.xiv-app_drawer_setting:before{content:'\e220';}
.xiv-DrawerCustom:before{content:'\e221';}
.xiv-DrawerDelete:before{content:'\e230';}
.xiv-DrawerExit:before{content:'\e231';}
.xiv-app_drawer_sort_online:before{content:'\e232';}
.xiv-app_drawer_sort_user:before{content:'\e233';}
.xiv-app_drawer_character_change:before{content:'\e234';}
.xiv-ViewRefresh:before{content:'\e240';}
.xiv-ViewLoad:before{content:'\e241';}
.xiv-app_view_list:before{content:'\e250';}
.xiv-app_view_grid:before{content:'\e251';}
.xiv-ViewDetail:before{content:'\e252';}
.xiv-app_drawer_service:before{content:'\e261';}
.xiv-app_drawer_news:before{content:'\e262';}
.xiv-app_drawer_guide:before{content:'\e263';}
.xiv-app_drawer_qa:before{content:'\e264';}
.xiv-app_drawer_support:before{content:'\e265';}
.xiv-app_drawer_edb:before{content:'\e266';}
.xiv-app_drawer_game_help:before{content:'\e267';}
.xiv-app_drawer_patchnote:before{content:'\e268';}
.xiv-app_notification_topics:before{content:'\e271';}
.xiv-app_notification_notices:before{content:'\e272';}
.xiv-app_notification_maintenance:before{content:'\e273';}
.xiv-app_notification_updates:before{content:'\e274';}
.xiv-app_notification_status:before{content:'\e275';}
.xiv-Chat:before{content:'\e300';}
.xiv-app_chat_bg:before{content:'\e303';}
.xiv-app_chat_bg_trim:before{content:'\e304';}
.xiv-app_chat_photo:before{content:'\e305';}
.xiv-MessageSend:before{content:'\e310';}
.xiv-app_chat_stamp:before{content:'\e311';}
.xiv-app_reaction_template:before{content:'\e313';}
.xiv-app_reaction_emoji_add:before{content:'\e314';}
.xiv-app_reaction_template_add:before{content:'\e315';}
.xiv-app_reaction_list:before{content:'\e316';}
.xiv-ChatReadOk:before{content:'\e320';}
.xiv-ChatMute:before{content:'\e321';}
.xiv-ChatQuote:before{content:'\e322';}
.xiv-ChatReadHidden:before{content:'\e323';}
.xiv-ChatDeleted:before{content:'\e324';}
.xiv-app_fc_chat_feature2:before{content:'\e341';}
.xiv-app_fellow:before{content:'\e350';}
.xiv-app_fellow_search:before{content:'\e351';}
.xiv-app_fellow_detail:before{content:'\e352';}
.xiv-app_fellow_board:before{content:'\e353';}
.xiv-app_fellow_member:before{content:'\e354';}
.xiv-app_fellow_member_master:before{content:'\e355';}
.xiv-app_fellow_member_admin:before{content:'\e356';}
.xiv-app_fellow_manage_master:before{content:'\e357';}
.xiv-app_fellow_manage_recruiter:before{content:'\e358';}
.xiv-app_fellow_topic:before{content:'\e361';}
.xiv-app_fellow_question:before{content:'\e362';}
.xiv-app_fellow_memo:before{content:'\e363';}
.xiv-app_fellow_activity:before{content:'\e364';}
.xiv-app_fellow_question_active:before{content:'\e371';}
.xiv-app_fellow_question_none:before{content:'\e372';}
.xiv-app_fellow_question_vote:before{content:'\e373';}
.xiv-app_fellow_question_most:before{content:'\e374';}
.xiv-app_fellow_recruit_joined:before{content:'\e381';}
.xiv-app_fellow_recruit_message:before{content:'\e382';}
.xiv-app_fellow_recruit_info:before{content:'\e383';}
.xiv-app_fellow_recruit_language:before{content:'\e384';}
.xiv-Schedule:before{content:'\e400';}
.xiv-ScheduleAdd:before{content:'\e402';}
.xiv-ScheduleCancel:before{content:'\e403';}
.xiv-ScheduleTime:before{content:'\e410';}
.xiv-ScheduleDetails:before{content:'\e411';}
.xiv-ScheduleMember:before{content:'\e412';}
.xiv-ScheduleHistory:before{content:'\e413';}
.xiv-ScheduleShare:before{content:'\e414';}
.xiv-Leader:before{content:'\e415';}
.xiv-NotificationOn:before{content:'\e450';}
.xiv-NotificationOff:before{content:'\e451';}
.xiv-ScheduleAlerm:before{content:'\e452';}
.xiv-app_schedule_repeat_parent:before{content:'\e461';}
.xiv-app_schedule_repeat_child:before{content:'\e462';}
.xiv-app_schedule_single:before{content:'\e463';}
.xiv-CheckBoxOff:before{content:'\e500';}
.xiv-CheckBoxOn:before{content:'\e501';}
.xiv-CheckBoxClear:before{content:'\e503';}
.xiv-RadioButtonOff:before{content:'\e505';}
.xiv-RadioButtonOn:before{content:'\e506';}
.xiv-FormEdit:before{content:'\e510';}
.xiv-CopyPaste:before{content:'\e520';}
.xiv-ItemBuyBack:before{content:'\e601';}
.xiv-Market:before{content:'\e700';}
.xiv-MarketLimit:before{content:'\e702';}
.xiv-MarketPurchase:before{content:'\e703';}
.xiv-app_market_exhibit:before{content:'\e704';}
.xiv-app_market_notification_alert:before{content:'\e705';}
.xiv-PaymentCoin:before{content:'\e800';}
.xiv-app_market_purchase:before{content:'\e801';}
.xiv-app_payment_symbol:before{content:'\e802';}
.xiv-app_home_menu_community:before{content:'\e841';}
.xiv-app_home_menu_schedule:before{content:'\e842';}
.xiv-app_home_menu_address:before{content:'\e843';}
.xiv-app_home_menu_inventory:before{content:'\e844';}
.xiv-app_home_menu_market_exhibit:before{content:'\e845';}
.xiv-app_home_menu_market_purchase:before{content:'\e846';}
.xiv-app_home_command_ok:before{content:'\e851';}
.xiv-app_home_command_ng:before{content:'\e852';}
.xiv-app_home_command_limit:before{content:'\e853';}
.xiv-app_home_command_view:before{content:'\e854';}
.xiv-SymbolAlert:before{content:'\e900';}
.xiv-SymbolQuestion:before{content:'\e901';}
.xiv-SymbolCheck:before{content:'\e902';}
.xiv-SymbolCross:before{content:'\e903';}
.xiv-SymbolMail:before{content:'\e904';}
.xiv-SymbolLock:before{content:'\e905';}
.xiv-ExternalLink:before{content:'\e906';}
.xiv-SquareBoxAlert:before{content:'\e910';}
.xiv-SquareBoxQuestion:before{content:'\e911';}
.xiv-SquareBoxCheck:before{content:'\e912';}
.xiv-SquareBoxCross:before{content:'\e913';}
.xiv-TriangleBoxAlert:before{content:'\e919';}
.xiv-SquareFrameCheck:before{content:'\e922';}
.xiv-SymbolInformation:before{content:'\e930';}
.xiv-app_symbol_info_circle:before{content:'\e931';}
.xiv-app_symbol_check_circle:before{content:'\e932';}
.xiv-SymbolMamet:before{content:'\e951';}
.xiv-SymbolMoogle:before{content:'\e952';}
.xiv-SymbolAlarmClock:before{content:'\e953';}
.xiv-app_symbol_dc:before{content:'\e956';}
.xiv-class_job_001:before{content:'\f001';}
.xiv-class_job_002:before{content:'\f002';}
.xiv-class_job_003:before{content:'\f003';}
.xiv-class_job_004:before{content:'\f004';}
.xiv-class_job_005:before{content:'\f005';}
.xiv-class_job_006:before{content:'\f006';}
.xiv-class_job_007:before{content:'\f007';}
.xiv-class_job_008:before{content:'\f008';}
.xiv-class_job_009:before{content:'\f009';}
.xiv-class_job_010:before{content:'\f010';}
.xiv-class_job_011:before{content:'\f011';}
.xiv-class_job_012:before{content:'\f012';}
.xiv-class_job_013:before{content:'\f013';}
.xiv-class_job_014:before{content:'\f014';}
.xiv-class_job_015:before{content:'\f015';}
.xiv-class_job_016:before{content:'\f016';}
.xiv-class_job_017:before{content:'\f017';}
.xiv-class_job_018:before{content:'\f018';}
.xiv-class_job_019:before{content:'\f019';}
.xiv-class_job_020:before{content:'\f020';}
.xiv-class_job_021:before{content:'\f021';}
.xiv-class_job_022:before{content:'\f022';}
.xiv-class_job_023:before{content:'\f023';}
.xiv-class_job_024:before{content:'\f024';}
.xiv-class_job_025:before{content:'\f025';}
.xiv-class_job_026:before{content:'\f026';}
.xiv-class_job_027:before{content:'\f027';}
.xiv-class_job_028:before{content:'\f028';}
.xiv-class_job_029:before{content:'\f029';}
.xiv-class_job_030:before{content:'\f030';}
.xiv-class_job_031:before{content:'\f031';}
.xiv-class_job_032:before{content:'\f032';}
.xiv-class_job_033:before{content:'\f033';}
.xiv-class_job_034:before{content:'\f034';}
.xiv-class_job_035:before{content:'\f035';}
.xiv-class_job_036:before{content:'\f036';}
.xiv-class_job_037:before{content:'\f037';}
.xiv-class_job_038:before{content:'\f038';}
.xiv-class_job_039:before{content:'\f039';}
.xiv-class_job_040:before{content:'\f040';}
.xiv-Armoury_MainArm:before{content:'\f081';}
.xiv-Armoury_SubArm:before{content:'\f082';}
.xiv-Armoury_Head:before{content:'\f083';}
.xiv-Armoury_Body:before{content:'\f084';}
.xiv-Armoury_Hands:before{content:'\f085';}
.xiv-Armoury_Waist:before{content:'\f086';}
.xiv-Armoury_Legs:before{content:'\f087';}
.xiv-Armoury_Feet:before{content:'\f088';}
.xiv-Armoury_Earrings:before{content:'\f089';}
.xiv-Armoury_Necklace:before{content:'\f090';}
.xiv-Armoury_Bracelets:before{content:'\f091';}
.xiv-Armoury_Ring:before{content:'\f092';}
.xiv-Armoury_SoulCrystal:before{content:'\f093';}
.xiv-ItemCategory_PGL:before{content:'\f101';}
.xiv-ItemCategory_GLA:before{content:'\f102';}
.xiv-ItemCategory_MRD:before{content:'\f103';}
.xiv-ItemCategory_LNC:before{content:'\f104';}
.xiv-ItemCategory_ARC:before{content:'\f105';}
.xiv-ItemCategory_ROG:before{content:'\f106';}
.xiv-ItemCategory_CNJ:before{content:'\f107';}
.xiv-ItemCategory_THM:before{content:'\f108';}
.xiv-ItemCategory_ACN:before{content:'\f109';}
.xiv-ItemCategory_Shield:before{content:'\f110';}
.xiv-ItemCategory_CRP:before{content:'\f112';}
.xiv-ItemCategory_BSM:before{content:'\f113';}
.xiv-ItemCategory_ARM:before{content:'\f114';}
.xiv-ItemCategory_GSM:before{content:'\f115';}
.xiv-ItemCategory_LTW:before{content:'\f116';}
.xiv-ItemCategory_WVR:before{content:'\f117';}
.xiv-ItemCategory_ALC:before{content:'\f118';}
.xiv-ItemCategory_CUL:before{content:'\f119';}
.xiv-ItemCategory_MIN:before{content:'\f120';}
.xiv-ItemCategory_BTN:before{content:'\f121';}
.xiv-ItemCategory_fisher:before{content:'\f122';}
.xiv-ItemCategory_Fishing_Tackle:before{content:'\f123';}
.xiv-ItemCategory_Medicine:before{content:'\f136';}
.xiv-ItemCategory_Bone:before{content:'\f144';}
.xiv-ItemCategory_Meal:before{content:'\f146';}
.xiv-ItemCategory_Dye:before{content:'\f147';}
.xiv-ItemCategory_Part:before{content:'\f148';}
.xiv-ItemCategory_Furnishing:before{content:'\f149';}
.xiv-ItemCategory_Materia:before{content:'\f150';}
.xiv-ItemCategory_Crystal:before{content:'\f151';}
.xiv-ItemCategory_Catalyst:before{content:'\f152';}
.xiv-ItemCategory_Miscellany:before{content:'\f153';}
.xiv-ItemCategory_Seasonal_Miscellany:before{content:'\f154';}
.xiv-ItemCategory_Minion:before{content:'\f155';}
.xiv-ItemCategory_Triple_Triad_Card:before{content:'\f156';}
.xiv-ItemCategory_Soul_Crystal:before{content:'\f157';}
.xiv-ItemCategory_Other:before{content:'\f159';}
.xiv-ItemCategory_Exterior_Fixtures:before{content:'\f160';}
.xiv-ItemCategory_Interior_Fixtures:before{content:'\f161';}
.xiv-ItemCategory_Tables:before{content:'\f162';}
.xiv-ItemCategory_Tabletop:before{content:'\f163';}
.xiv-ItemCategory_Furnishings:before{content:'\f164';}
.xiv-ItemCategory_Chairs_and_Beds:before{content:'\f165';}
.xiv-ItemCategory_Wallmounted:before{content:'\f166';}
.xiv-ItemCategory_Rug:before{content:'\f167';}
.xiv-ItemCategory_Outdoor_Furnishings:before{content:'\f168';}
.xiv-ItemCategory_Airship:before{content:'\f169';}
.xiv-ItemCategory_DRK:before{content:'\f170';}
.xiv-ItemCategory_AST:before{content:'\f171';}
.xiv-ItemCategory_MCH:before{content:'\f172';}
.xiv-ItemCategory_Orchestrion_Roll:before{content:'\f173';}
.xiv-ItemCategory_Gardening:before{content:'\f174';}
.xiv-ItemCategory_Painting:before{content:'\f175';}
.xiv-ItemCategory_RDM:before{content:'\f176';}
.xiv-ItemCategory_SAM:before{content:'\f177';}
.xiv-ItemCategory_SCH:before{content:'\f178';}
.xiv-ItemCategory_BLU:before{content:'\f180';}
.xiv-ItemAll:before{content:'\f200';}
.xiv-Bag:before{content:'\f201';}
.xiv-ArmoryChest:before{content:'\f202';}
.xiv-Gil:before{content:'\f203';}
.xiv-Retainer:before{content:'\f204';}
.xiv-ChocoboStorage:before{content:'\f205';}
.xiv-ItemShard:before{content:'\f206';}
.xiv-TrashCan:before{content:'\f208';}
.xiv-Wallet:before{content:'\f209';}
.xiv-CharaChange:before{content:'\f210';}
.xiv-CommandIn:before{content:'\f211';}
.xiv-CommandChange:before{content:'\f212';}
.xiv-CommandAdd:before{content:'\f213';}
.xiv-MarketFilter:before{content:'\f214';}
.xiv-MarketSort:before{content:'\f215';}
.xiv-item_command_move_ac:before{content:'\f216';}
.xiv-item_command_merge:before{content:'\f218';}
.xiv-ListView:before{content:'\f219';}
.xiv-RoundArrowUp:before{content:'\f221';}
.xiv-RoundArrowDown:before{content:'\f222';}
.xiv-RoundArrowRight:before{content:'\f223';}
.xiv-TradeUp:before{content:'\f224';}
.xiv-TradeDown:before{content:'\f225';}
.xiv-item_storage_search:before{content:'\f226';}
.xiv-GlamoursBase:before{content:'\f231';}
.xiv-GlamoursColor:before{content:'\f232';}
.xiv-GlamoursNullBase:before{content:'\f233';}
.xiv-GlamoursNullColor:before{content:'\f234';}
.xiv-PaintBorder:before{content:'\f235';}
.xiv-PaintColor:before{content:'\f236';}
.xiv-PaintBorderLarge:before{content:'\f237';}
.xiv-PaintColorLarge:before{content:'\f238';}
.xiv-item_signature:before{content:'\f239';}
.xiv-item_stain_condition:before{content:'\f240';}
.xiv-StorageCharacter:before{content:'\f300';}
.xiv-SquareNo01:before{content:'\f301';}
.xiv-SquareNo02:before{content:'\f302';}
.xiv-SquareNo03:before{content:'\f303';}
.xiv-SquareNo04:before{content:'\f304';}
.xiv-SquareNo05:before{content:'\f305';}
.xiv-SquareNo06:before{content:'\f306';}
.xiv-SquareNo07:before{content:'\f307';}
.xiv-SquareNo08:before{content:'\f308';}
.xiv-SquareNo09:before{content:'\f309';}
.xiv-SquareNo10:before{content:'\f310';}
.xiv-ChocoboStorage1:before{content:'\f331';}
.xiv-ChocoboStorage2:before{content:'\f332';}
.xiv-CharacterEmpty:before{content:'\f341';}
.xiv-fellow_activity_00:before{content:'\f400';}
.xiv-fellow_activity_01:before{content:'\f401';}
.xiv-fellow_activity_02:before{content:'\f402';}
.xiv-fellow_activity_03:before{content:'\f403';}
.xiv-fellow_activity_04:before{content:'\f404';}
.xiv-fellow_activity_05:before{content:'\f405';}
.xiv-fellow_activity_06:before{content:'\f406';}
.xiv-fellow_activity_07:before{content:'\f407';}
.xiv-fellow_activity_08:before{content:'\f408';}
.xiv-fellow_activity_09:before{content:'\f409';}
.xiv-fellow_activity_10:before{content:'\f410';}
.xiv-fellow_activity_11:before{content:'\f411';}
.xiv-fellow_activity_12:before{content:'\f412';}
.xiv-fellow_activity_13:before{content:'\f413';}
.xiv-fellow_activity_14:before{content:'\f414';}
.xiv-fellow_activity_15:before{content:'\f415';}
.xiv-fellow_activity_16:before{content:'\f416';}
.xiv-fellow_activity_17:before{content:'\f417';}
.xiv-fellow_activity_18:before{content:'\f418';}
.xiv-fellow_activity_19:before{content:'\f419';}
.xiv-fellow_activity_20:before{content:'\f420';}
.xiv-fellow_activity_21:before{content:'\f421';}
.xiv-fellow_activity_22:before{content:'\f422';}
.xiv-fellow_emote_00:before{content:'\f500';}
.xiv-fellow_emote_01:before{content:'\f501';}
.xiv-fellow_emote_02:before{content:'\f502';}
.xiv-fellow_emote_03:before{content:'\f503';}
.xiv-fellow_emote_04:before{content:'\f504';}
.xiv-fellow_emote_05:before{content:'\f505';}
.xiv-fellow_emote_06:before{content:'\f506';}
.xiv-fellow_emote_07:before{content:'\f507';}
.xiv-reaction_emoji_category_00:before{content:'\f600';}
.xiv-reaction_emoji_category_01:before{content:'\f601';}
.xiv-reaction_emoji_category_02:before{content:'\f602';}
.xiv-reaction_emoji_category_03:before{content:'\f603';}
.xiv-reaction_emoji_category_04:before{content:'\f604';}
.xiv-reaction_emoji_category_05:before{content:'\f605';}
.xiv-reaction_emoji_category_06:before{content:'\f606';}
.xiv-reaction_emoji_category_07:before{content:'\f607';}
.xiv-reaction_emoji_category_08:before{content:'\f608';}
.xiv-reaction_emoji_category_09:before{content:'\f609';}
.xiv-reaction_emoji_category_10:before{content:'\f610';}